// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const AuthContext = createContext();

// Function to generate a token (simple example)
const generateToken = () => {
    return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
};

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();

    // Load token and user info from localStorage when the app initializes
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        const storedUserInfo = localStorage.getItem("userInfo");
        
        if (token) {
            setAuthToken(token);
        }
        if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []);

    // Function to register a user
    const register =async (values) => {
        // Generate a token
        const token = generateToken();


        const params = new URLSearchParams();
        params.append('user_email', values.emailOrPhone);
        params.append('user_whatsapp', values.Number);
        params.append('user_fullname', values.Name);
        params.append('user_real_pass', values.password);
        params.append('table_name', 'users');
    
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 20000); // 20 seconds timeout
    
          const response = await axios.post('https://api.betprowallet.pk/createNewAccUserFromServer.php', params, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: source.token,
          });
    
          clearTimeout(timeout);
    
          console.log('Response data:', response.data);
    
          if (response.data.status === 'success') {
          
          
            localStorage.removeItem('user');
    
            const user = {
              name: response.data.data[0]?.user_fullname || 'User',
              bp_username: response.data.data[0]?.user_betpro_username || '',
              bp_pass: response.data.data[0]?.user_betpro_password || '',
              email: response.data.data[0]?.user_email || '',
              password: '',
              number: response.data.data[0]?.user_whatsapp || 'N/A',
              userID: response.data.data[0]?.user_id || 'N/A',
              userActive: response.data.data[0]?.user_active || 0,
          };
    
        
    
        
          localStorage.setItem("userInfo", JSON.stringify(user));
          localStorage.setItem("authToken", token);
  
          // Set the token and user info in state
          setAuthToken(token);
          setUserInfo(user);
  
          // Redirect to the dashboard
          navigate("/dashboard");
    
          
    
          } else {
            alert(response.data.message || 'Sign up failed. Please try again.');
          
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            alert('Request timed out. Please try again.');
          } else {
            alert('Please check your internet connection...');
          }
          
        } finally {
         
        }




    };


     // Function to register a user
     const login = async (values) => {
       
        const token = generateToken();

    

       const emailOrPhone =  values.emailOrPhone;
       const password = values.password;


        const userField = emailOrPhone.length === 11 && !emailOrPhone.includes('@') ? 'user_number' : 'user_email';

        const params = new URLSearchParams();
        params.append('username', emailOrPhone);
        params.append('real_pass', password);
        params.append('fieldName', userField);
        params.append('table_name', 'users');

        try {
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 20000); // 20 seconds timeout

            const response = await axios.post('https://api.betprowallet.pk/loginUserApi_ft.php', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                cancelToken: source.token,
            });

            clearTimeout(timeout);


            


            if (response.data.message === '0 results') {
               
            } else {




                // Check if response starts with "0 results"
                if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {

                    alert('User not found. Please try again.');
                    navigate("/");
                   
                } else {



                    if (response.data[0].user_blocked === '0') {

                        localStorage.removeItem('user');

                        const user = {
                            name: response.data[0]?.user_fullname || 'User',
                            bp_username: response.data[0]?.user_betpro_username || '',
                            bp_pass: response.data[0]?.user_betpro_password || '',
                            email: response.data[0]?.user_email || '',
                            password: '',
                            number: response.data[0]?.user_whatsapp || 'N/A',
                            userID: response.data[0]?.user_id || 'N/A',
                            userActive: response.data[0]?.user_active || 0,
                        };




      

        localStorage.setItem("userInfo", JSON.stringify(user));
        localStorage.setItem("authToken", token);

        // Set the token and user info in state
        setAuthToken(token);
        setUserInfo(user);

        // Redirect to the dashboard
        navigate("/dashboard");

       

                    } else {

                        alert('User not found. Your email, phone number or password is incorrect.');                      

                    }




                }







            }

        } catch (error) {
            if (axios.isCancel(error)) {


               
            } else {
               
            }


     
         

            
        } finally {
           
        }
   

    };



    // Function to log out (remove token and user info from localStorage)
    const logout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        setAuthToken(null);
        setUserInfo(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken,userInfo, login,register, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
