  
  import axios  from "axios";

  
  // Function to check for pending deposits
  export const sendFCMNotification = async ( topic, title, msg) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('target', topic);
      params.append('title', title);
      params.append('body', msg);
      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_sendmsg.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);




      // console.log(response.data);
    








    } catch (error) {
      if (axios.isCancel(error)) {
        
        
      } else {
       
      }

      // console.log(error);
     

    }
  };
