import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/auth/Login/Login';
import Register from '../pages/auth/register/Register';
import ForgetPassword from '../pages/auth/ForgetPassword/ForgetPassword';
import Home from '../pages/dashboard/Home/Home';
import Dashboard from '../pages/dashboard/Dashboard';
import Deposit from '../pages/dashboard/Deposit/Deposit';
import Withdraw from '../pages/dashboard/Withdraw/Withdraw';
import Menu from '../pages/dashboard/Menu/Menu';
import History from '../pages/dashboard/History/History';
import Bpro from '../pages/dashboard/Bpro/Bpro';
import Main from '../pages/dashboard/Main/Main';
import { useAuth } from '../context/AuthContext';

const Routing = () => {
   const { authToken } =useAuth()

    return (
        <Routes>
            {/* Redirect based on authentication status */}
            {authToken ? (
                <>
                    {/* Authenticated Routes */}
                    <Route path='/login' element={<Navigate to="/dashboard" />} />
                    <Route path='/signup' element={<Navigate to="/dashboard" />} />
                    <Route path='/forget/password' element={<Navigate to="/dashboard" />} />
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="*" element={<Navigate to="/dashboard" />} />



                   
                   
                    <Route path='/dashboard' element={<Dashboard />}>
                        <Route index element={<Navigate to="/dashboard/home" />} />
                        <Route path='home' element={<Home />} />
                        <Route path='deposit' element={<Deposit />} />
                        <Route path='menu' element={<Menu />} />
                        <Route path='withdraw' element={<Withdraw />} />
                        <Route path='history' element={<History />} />
                        <Route path='bpro' element={<Bpro />} />
                        <Route path='main' element={<Main />} />
                    </Route>
                  

                </>
            ) : (
                <>
                    {/* Unauthenticated Routes */}
                    <Route path='/login' element={<Login />} />
                    <Route path='/signup' element={<Register />} />
                    <Route path='/forget/password' element={<ForgetPassword />} />
               
                    {/* <Route path="/" element={<Navigate to="/login" />} /> */}

                    {/* Redirect any /dashboard path to login if not authenticated */}
                    <Route path="/dashboard" element={<Navigate to="/login" />} />
                </>
            )}
            {/* Catch-all redirect */}
            <Route path="/" element={<Navigate to={authToken  ? "/dashboard" : "/login"} />} />
        </Routes>
    );
};

export default Routing;
