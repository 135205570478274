import React, { useState } from 'react';
import "./Menu.css";
import Header from '../../../components/Header';
import { Icons } from '../../../content/Icons';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';

const Menu = () => {

    const user = localStorage.getItem('userInfo');
    const parsedUser = JSON.parse(user);

    console.log(parsedUser);


    const {logout} =useAuth()
    const [isLogout, setIsLogout] = useState(false);
    const navigate = useNavigate();


    const handleWithdraw = () => {
        navigate("/dashboard/withdraw")
    };

    const logoutHandler = () => {
        setIsLogout(true);
    }
    return (
        <div>
            <Header title="menu" logoutHandler={logoutHandler} />
            <div className="menu-area  max-w-md mx-auto py-8 px-3">
                <div className="menu-items mb-10 py-4">
                    <div className="flex items-center px-5 my-4">
                        <div className="w-1/2">
                            <h3>Name</h3>
                        </div>
                        <div className="w-1/2 text-right">
                            <h3>{parsedUser.name}</h3>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <div className="flex items-center px-5 my-4">
                        <div className="w-1/2">
                            <h3>Number</h3>
                        </div>
                        <div className="w-1/2 text-right">
                            <h3>{parsedUser.number}</h3>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="flex items-center px-5 my-4">
                        <div className="w-1/2">
                            <h3>Email</h3>
                        </div>
                        <div className="w-1/2 text-right">
                            <h3>{parsedUser.email}</h3>
                        </div>
                    </div>
                </div>

                <div className="withdraw-btn w-full my-5">
                    <button type="button" onClick={handleWithdraw}>Withdraw</button>
                </div>


                {isLogout && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="menu-modal">
                            <div className="logout-content flex flex-col justify-between h-full items-center">
                                <img src={Icons.signOutIcon} alt="logout" />
                                <h2>Are you sure!</h2>
                                <button onClick={() => logout()}>logout</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Menu;
