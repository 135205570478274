import { useEffect } from 'react';
import './App.css';
import Routing from './Routes/Routing';
import { AuthProvider } from './context/AuthContext';

function App() {
  useEffect(() => {
    const preventZoom = (e) => {
      e.preventDefault();
    };

    document.addEventListener('gesturestart', preventZoom);

    return () => {
      document.removeEventListener('gesturestart', preventZoom);
    };
  }, []);
  return (
    <>
    <AuthProvider>
    <Routing/>
    </AuthProvider>

    </>
  );
}

export default App;
