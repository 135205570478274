import { useFormik } from "formik";
import * as Yup from "yup";
import "./ForgetPassword.css";
import { Icons } from "../../../content/Icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";

const ForgetPassword = () => {
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    // Validation schema using Yup
    const validationSchema = Yup.object({
        emailOrPhone: Yup.string().required("Please provide a correct email").email("Invalid email format")
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            emailOrPhone: "",
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Submitted:", values);
            setIsSuccess(true);
        },
    });

    return (
        <div className="forget-password-area">
            <div className="bg-[#FFFFFF]">
                <Header title="Betpro Wallet"/>
                <div className="w-full max-w-md mx-auto flex flex-col items-center justify-center h-[80vh] relative">
                    {/* Forget Password Logic */}
                    {isSuccess ? (
                        <div className="w-full px-3">
                            <div className="flex justify-center flex-col items-center">
                                <div className="my-6">
                                    <h2 className="px-3 font-['Inter',sans-serif] text-[14px] font-[400] text-[#27312C] text-center">
                                        We have sent password recovery instructions to your email.
                                    </h2>
                                </div>
                            </div>

                            {/* Success Form */}
                            <form className="reset-message mt-4 w-full">
                                <div>
                                    <div className="flex justify-between mt-4 space-x-0">
                                        <div className="w-full p-0 m-0">
                                            <button className="register-btn w-full" type="button" onClick={() => navigate('/login')}>OK</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="w-full px-3">
                            <div className="flex justify-center flex-col items-center">
                                <img src={Icons.forgotPasswordIcon} alt="icon" />
                                <div className="my-6">
                                    <h2 className="px-3 font-['Inter',sans-serif] text-[14px] font-[400] text-[#27312C] text-center desc-txt">
                                        Enter your registered email below to receive password reset instructions.
                                    </h2>
                                </div>
                            </div>
                            <div className="mt-6">
                                <h2 className="px-3 font-['Inter',sans-serif] text-[16px] font-[700] text-[#27312C]">Forget your password?</h2>
                            </div>

                            {/* Forget Password Form */}
                            <form onSubmit={formik.handleSubmit} className="forget-password-form mt-4 w-full">
                                <div>
                                    {/* Email Input */}
                                    <div className="form-group pt-2">
                                        <div className="relative">
                                            <input
                                                className={`form-control ${formik.touched.emailOrPhone && formik.errors.emailOrPhone ? "border-red-500" : "border-gray-300"}`}
                                                placeholder="Email"
                                                type="email"
                                                name="emailOrPhone"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.emailOrPhone}
                                            />
                                            <div className="absolute left-0 flex items-center h-full top-0 px-[20px]">
                                                <img src={Icons.mailIcon} alt="userIcon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="divider"></div>

                                    {/* Error Message */}
                                    <div className="error-txt pt-2">
                                        {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
                                            <p className="invalid-txt pl-2">
                                                {formik.errors.emailOrPhone}
                                            </p>
                                        ) : null}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="flex justify-between mt-4 space-x-0">
                                        <div className="w-full p-0 m-0">
                                            <button className="register-btn w-full" type="submit">Send</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;


