import React from 'react'
import "./Home.css"
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Icons } from '../../../content/Icons';
import Header from '../../../components/Header';
import { useNavigate } from "react-router-dom";
const Home = () => {

    const navigate = useNavigate();


    const user = localStorage.getItem('userInfo');
    const parsedUser = JSON.parse(user);



    const validationSchema = Yup.object({
        betproId: Yup.string()
            .required("Please provide a Betpro Id"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .required("Password is required"),
    });

    // Formik setup
    const formik = useFormik({


        initialValues: {
            betproId: parsedUser.bp_username || "",
            password: parsedUser.bp_pass || "",
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Submitted:", values);
        },
    });

    const copyToClipboard1 = () => {
        navigator.clipboard.writeText(formik.values.betproId)
            .then(() => {

                alert("Betpro ID copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    const copyToClipboard2 = () => {
        navigator.clipboard.writeText(formik.values.password)
            .then(() => {

                alert("Betpro Password copied to clipboard!");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <>
            <Header title="Betpro Wallet" />
            <div className='w-full max-w-md mx-auto home-area px-3  relative'>

                {parsedUser.userActive == 0 ? (
                    <div className="top-bar pt-5">
                        <h1>Betpro Account is not activated</h1>
                    </div>

                ) : (
                    <div className="top-bar pt-5">
                        <h1>Betpro Account is activated</h1>
                    </div>
                )

                }

                <form
                    onSubmit={formik.handleSubmit}
                    className="home-form mt-4 w-full mb-10"
                >
                    <div>
                        {/* Betpro ID: */}
                        <div className="form-group">
                            <div className="relative flex items-center">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.userIcon} alt="userIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Betpro ID:</p>
                                </div>
                                <input
                                    className={`form-control ${formik.touched.betproId && formik.errors.betproId
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type="text"
                                    name="betproId"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.betproId}
                                    disabled
                                />
                                {parsedUser.userActive == 1 ? (
                                    <button type="button" onClick={copyToClipboard1} className="ml-2">
                                        <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        <div className="divider"></div>

                        <div className="form-group">
                            <div className="relative flex items-center">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.lockIcon} alt="userIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Password:</p>
                                </div>
                                <input
                                    className={`form-control ${formik.touched.password && formik.errors.password
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type={"text"}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    disabled
                                />
                                {parsedUser.userActive == 1 ? (
                                    <button type="button" onClick={copyToClipboard2} className="ml-2">
                                        <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                                    </button>
                                ) : null}

                            </div>
                        </div>

                        <div className="flex items-center justify-between px-[10px] pt-2 pb-2">
                            <div className="error-txt">
                                {formik.touched.betproId && formik.errors.betproId ? (
                                    <p color="danger invalid-txt">
                                        {formik.errors.betproId}
                                    </p>
                                ) : null}
                                {formik.touched.password && formik.errors.password ? (
                                    <p color="danger invalid-txt">
                                        {formik.errors.password}
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        {parsedUser.userActive == 0 ? (

                            <div className="flex">
                                <button type="button" className='activated-btn' onClick={()=>{navigate('/dashboard/deposit');}}>Activate account</button>
                            </div>



                        ) : null}



                    </div>
                </form>
                <div className="user-instructions">
                    <h2 className='user-title pb-4'>New user instructions</h2>
                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon1} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}>1st deposit karne per apka Betpro account active hojae ga or ID & password uper show hojae ge.</p>
                        </div>
                    </div>
                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon2} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}>Deposit ki gayi raqam apke Betpro account me add hojae gi.</p>
                        </div>
                    </div>

                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon3} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}>Deposit karne ke 10 minutes baad apki id activate ho jaye gi.</p>
                        </div>
                    </div>
                </div>
                <div className=" absolute bottom-[10%] right-5">
                    <button type="button" onClick={() => { window.location.href = "https://wa.me/+923844845548"; }}>
                        <img src={Icons.logos_whatsapp} alt="logos_whatsapp" className=' w-[45.37px] h-[45.37px]' />
                    </button>
                </div>
            </div>

        </>
    )
}

export default Home
