import React from "react";
import "./Bpro.css";
import Header from "../../../components/Header";
const Bpro = () => {


  return (
    <div>
      <Header title="Bpro" />
      <div className="bpro-area p-4 max-w-md mx-auto">
        <div className="bpro-items">
          <div className="coming-soon flex items-center justify-center">
            <h1>coming soon</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bpro;
