import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../content/Icons";
import "./BottomTab.css";
import { useState } from "react";

const BottomTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("home"); // Track active tab based on current URL

  const handleNavigate = (path) => {

    if (path === "bpro") {
      // window.location.href = "https://m.youtube.com/watch?v=TkktPuc83QY&pp=ygUeaG93IHRvIGNyZWF0ZSB3aGF0c2FwcCBjaGFubmVs";

      window.location.href = "https://bpexch.com";
  }else{
    setActiveTab(path); // Set the active tab when navigating
    navigate(`${path}`);
  }
  };

  return (
    <div className="w-full px-4 bg-[#27312C] fixed bottom-0 left-0 right-0 z-30">
      <div className="flex items-center justify-between max-w-md mx-auto h-[60px]">
        {/* Main Button */}
        <button
          onClick={() => handleNavigate("home")}
          className={`flex flex-col justify-center items-center ${activeTab === "/home" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-2 h-[17px]">
            <img src={activeTab === "home" ? Icons.activeMainIcon : Icons.mainIcon} alt="Main Icon" />
          </div>
          <p className={activeTab === "home" ? "text-[#F09C67]" : ""}>Main</p>
        </button>

        {/* History Button */}
        <button
          onClick={() => handleNavigate("history")}
          className={`flex flex-col justify-center items-center ${activeTab === "/history" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-2 h-[17px]">
            <img src={activeTab === "history" ? Icons.activeHistoryIcon : Icons.historyIcon} alt="History Icon" />
          </div>
          <p className={activeTab === "history" ? "text-[#F09C67]" : ""}>History</p>
        </button>

        {/* Deposit Button */}
        <button
          onClick={() => handleNavigate("deposit")}
          className={`relative flex flex-col justify-center items-center ${activeTab === "/deposit" ? "text-[#F09C67]" : ""}`}
        >
          <div className="absolute z-40 top-[-39px]">
            <div className="bg-[#F09C67] h-[57px] w-[57px] rounded-full flex items-center justify-center">
              <img src={Icons.depositIcon} alt="Deposit Icon" />
            </div>
          </div>
          <div className="mb-1 h-[17px]"></div>
          <p className={activeTab === "deposit" ? "text-[#F09C67]" : ""}>Deposit</p>
        </button>

        {/* Bpro Button */}
        <button
          onClick={() => handleNavigate("bpro")}
          className={`flex flex-col justify-center items-center ${activeTab === "bpro" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-1 h-[17px]">
            <img src={activeTab === "bpro" ? Icons.activeBproIcon : Icons.broIcon} alt="Bpro Icon" />
          </div>
          <p className={activeTab === "bpro" ? "text-[#F09C67]" : ""}>Bpro</p>
        </button>

        {/* Menu Button */}
        <button
          onClick={() => handleNavigate("menu")}
          className={`flex flex-col justify-center items-center ${activeTab === "menu" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-1 h-[17px]">
            <img src={activeTab === "menu" ? Icons.activeMenuIcon : Icons.menuIcon} alt="Menu Icon" />
          </div>
          <p className={activeTab === "menu" ? "text-[#F09C67]" : ""}>Menu</p>
        </button>
      </div>
    </div>
  );
};

export default BottomTab;
