import React, { useEffect, useState } from "react";
import "./History.css";
import Header from "../../../components/Header";
import { Icons } from "../../../content/Icons";

import axios from 'axios';

const History = () => {


  const user = localStorage.getItem('userInfo');
  const parsedUser = JSON.parse(user);

  
  // const historyList = [
  //   {
      // type: "Deposit",
      // name: "Easypaisa",
      // amount: "10,000",
      // status: "Pending",
      // timestamp: "22.07.2024 - 10:12 pm",
      // icon: Icons.historyFile,
      // statusIcon: Icons.historyFile, // Add the status icon here
  //   },
  //   {
  //     type: "Deposit",
  //     name: "Easypaisa",
  //     amount: "10,000",
  //     status: "Approved",
  //     timestamp: "22.07.2024 - 10:12 pm",
  //     icon: Icons.successCheck,
  //     statusIcon: Icons.successCheck,
  //   },
  //   {
  //     type: "Deposit",
  //     name: "Easypaisa",
  //     amount: "10,000",
  //     status: "Rejected",
  //     timestamp: "22.07.2024 - 10:12 pm",
  //     icon: Icons.successCheck,
  //     statusIcon: Icons.successCheck,
  //   },
  //   {
  //     type: "Withdraw",
  //     name: "Easypaisa",
  //     amount: "10,000",
  //     status: "Pending",
  //     timestamp: "22.07.2024 - 10:12 pm",
  //     icon: Icons.successCheck,
  //     statusIcon: Icons.successCheck,
  //   },
  //   {
  //     type: "Withdraw",
  //     name: "JazzCash",
  //     amount: "10,000",
  //     status: "Approved",
  //     timestamp: "22.07.2024 - 10:12 pm",
  //     icon: Icons.successCheck,
  //     statusIcon: Icons.successCheck,
  //   },
  //   {
  //     type: "Withdraw",
  //     name: "Easypaisa",
  //     amount: "10,000",
  //     status: "Rejected",
  //     timestamp: "22.07.2024 - 10:12 pm",
  //     icon: Icons.easypaisaIcon,
  //     statusIcon: Icons.successCheck,
  //   },
  // ];

  const [historyList, setTransactions] = useState([]);

 

 
 




  // Function to check for pending deposits
  const checkDepositsAndWithdraws = async (userName, setTransactions) => {
  
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);


      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api.betprowallet.pk/api_fetch_pending_deposits_withdraw.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);




      if (response.data) {





        const tempTransactions = [];

        response.data.map((item) => {
          tempTransactions.push({
            type: item.transaction_type === 2 ? 'Withdraw' : 'Deposit',
            name: item.bank_name,
            amount: item.amount,
            status: item.status === 0 ? 'Pending' : item.status === 1 ? 'Approved' : 'Rejected',
            timestamp: item.date_and_time,
            statusIcon:  item.status === 0 ?  Icons.historyFile : item.status === 1 ? Icons.successCheck : Icons.successCheck,
          });
        });



       

        setTransactions(tempTransactions);


        if (tempTransactions.length === 0) {
         

        }






      }








    } catch (error) {
      if (axios.isCancel(error)) {

      } else {

      }


    }
  };


  checkDepositsAndWithdraws(parsedUser.userID, setTransactions);

  return (
    <div>
      <Header title="History" />
      <div className="history-area p-4 max-w-md mx-auto">
        {historyList.map((item, index) => (
          <div className="history-items mb-5" key={index}>
            <div className="flex items-center justify-between">
              <div className="w-8/12">
                <h2>
                  {item.type} via {item.name}
                </h2>
                <h3>
                  Amount: <strong>rs. {item.amount}</strong>
                </h3>
                <h3>
                  Status: <span>{item.status}</span>
                </h3>
              </div>
              <div className="w-4/12 flex flex-col justify-center items-center">
                <img src={item.statusIcon} alt="status icon" className="mb-2" />
                <p>{item.timestamp}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default History;
