import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import { Icons } from "../../../content/Icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { useAuth } from '../../../context/AuthContext'; // Adjust path if necessary

const Login = () => {
  // Validation schema using Yup
  const { login } = useAuth(); // Access setAuthToken from context
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const route = useNavigate()
  const validationSchema = Yup.object({
    emailOrPhone: Yup.string()
    .required("Please provide an email or phone number")
    .test(
      "emailOrPhone",
      "Please provide a valid email or phone number",
      function (value) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{8,15}$/; // Accepts phone numbers between 8 and 15 digits
        return emailRegex.test(value) || phoneRegex.test(value);
      }
    ),
   
      password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      emailOrPhone: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log("Form Submitted:", values);
      login(values); // Set the token upon successful login
      // setIsError(true);
      
      route("/dashboard")

    },
  });

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const changeRoute = () => {
    route("/signup")
  }

  return (
    <div className="login-area">
      <div className="bg-[#FFFFFF]">
        <Header title="Betpro Wallet" />
        <div className="w-full max-w-md flex flex-col items-center justify-center h-[80vh] relative mx-auto">
          <div className="w-full px-3">
            {/* Welcome Text */}
            <div className="mt-6">
              <h2 className="px-3 font-['Inter',sans-serif] text-[16px] font-[700] text-[#27312C]">
                Welcome back!
              </h2>
            </div>

            {/* Login Form */}
            <form
              onSubmit={formik.handleSubmit}
              className="login-form mt-4 w-full"
            >
              <div>
                {/* Email Input */}
                <div className="form-group">
                  <div className="relative">
                    <input
                      className={`form-control ${formik.touched.emailOrPhone && formik.errors.emailOrPhone
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      placeholder="Email or Phone"
                      type="text"
                      name="emailOrPhone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.emailOrPhone}
                    />
                    <div className="absolute left-0 flex items-center h-full top-0 px-[20px]">
                      <img src={Icons.userIcon} alt="userIcon" />
                    </div>
                  </div>
                </div>
                <div className="divider"></div>

                {/* Password Input */}
                <div className="form-group">
                  <div className="relative">
                    <input
                      className={`form-control ${formik.touched.password && formik.errors.password
                          ? "border-red-500"
                          : "border-gray-300"
                        }`}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <div className="absolute left-0 flex items-center h-full top-0 px-[20px]">
                      <img src={Icons.lockIcon} alt="lockIcon" />
                    </div>
                    <div className="absolute right-0 flex items-center h-full top-0 px-[10px] z-10">
                      <button
                        type="button"
                        className="password-btn cursor-pointer"
                        onClick={handleClick}
                      >
                        <img
                          src={
                            showPassword
                              ? Icons.eyeSlashIcon
                              : Icons.eyeIcon
                          }
                          alt="eyeIcon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex items-center justify-between px-[10px] pt-2">
                  <div className="error-txt">
                    {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
                      <p color="danger invalid-txt">
                        {formik.errors.emailOrPhone}
                      </p>
                    ) : null}

                    {formik.touched.password && formik.errors.password ? (
                      <p color="danger invalid-txt">
                        {formik.errors.password}
                      </p>
                    ) : null}
                    {isError && <p>Username or password incorrect</p>}
                  </div>
                  <div className="flex items-center justify-end">
                    <button className="forget-btn" type="button" onClick={() => route("/forget/password")}>
                      Forget Password?
                    </button>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex justify-between mt-4 space-x-0">
                  <div className="w-1/2 p-0 m-0">
                    <button type="submit" className="login-btn w-full">
                      Login
                    </button>
                  </div>
                  <div className="w-1/2 p-0 m-0">
                    <button
                      type="button"
                      className="register-btn w-full"
                      onClick={changeRoute}
                    >
                      Signup
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
