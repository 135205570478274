import React from 'react';
import { Icons } from '../content/Icons';

const Header = ({title,logoutHandler}) => {
  return (
    <div className='bg-[#27312C] w-full'>
      <div className=" font-bold rounded-t-md h-[60px] flex items-center w-full max-w-md mx-auto justify-between ">
        <h2 className="px-3 font-['Inter',sans-serif] text-[20px] font-[700] text-[#FFFFFF] capitalize">
          {title}
        </h2>
        {
          logoutHandler &&(
            <button type="button" onClick={logoutHandler} className='px-3'>
            <img src={Icons.logoutIcon} alt="logoutIcon" className='w-[28px] h-[auto] block' />
          </button>
          )
        }

      </div>
    </div>
  );
};

export default Header;
