import React from 'react';
import "./Main.css";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Icons } from '../../../content/Icons';
import Header from '../../../components/Header';

const Main = () => {
    const validationSchema = Yup.object({
        betproId: Yup.string()
            .required("Please provide a Betpro Id"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .required("Password is required"),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            betproId: "",
            password: "",
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Submitted:", values);
        },
    });

    // Function to copy input text
    const handleCopy = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            alert('Text copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <>
            <Header title="Betpro Wallet" />
            <div className='w-full max-w-md mx-auto main-area px-3 relative'>
                <div className="top-bar pt-5">
                    <h1>Betpro Account is not activated</h1>
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="main-form mt-4 w-full mb-10"
                >
                    <div>
                        {/* Betpro ID */}
                        <div className="form-group my-1">
                            <div className="relative flex items-center px-4">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.userIcon} alt="userIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Betpro ID:</p>
                                </div>

                                <input
                                    className={`form-control ${formik.touched.betproId && formik.errors.betproId
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type="text"
                                    name="betproId"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.betproId}
                                    disabled
                                />
                                <div className="absolute top-0 right-3 h-full px-3 flex items-center">
                                    <button type="button" onClick={() => handleCopy(formik.values.betproId)}>
                                        <img src={Icons.copyIcon2} alt="copyIcon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="divider"></div>

                        {/* Password */}
                        <div className="form-group my-1">
                            <div className="relative flex items-center px-4">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.lockIcon} alt="lockIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Password:</p>
                                </div>
                                <input
                                    className={`form-control ${formik.touched.password && formik.errors.password
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type={"password"}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    disabled
                                />
                                <div className="absolute top-0 right-3 h-full px-3 flex items-center">
                                    <button type="button" onClick={() => handleCopy(formik.values.password)}>
                                        <img src={Icons.copyIcon2} alt="copyIcon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="user-instructions">
                    <h2 className='user-title pb-4'>Instructions</h2>
                    <ul>
                        <li className='flex items-start mb-2 '>
                            <p className='text-[#27312C] font-[400] text-[10px] pr-2' style={{ lineHeight: '12.1px' }}>1. </p>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}> Deposit karne ke baad 10 minutes ke ander payment proof send karen.</p>
                        </li>

                        <li className='flex items-start mb-2'>
                            <p className='text-[#27312C] font-[400] text-[10px] pr-2' style={{ lineHeight: '12.1px' }}>2. </p>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}> Wallet me diye gaye numbers ko save karne ya un pr call karne se apki raqam zaya ho sakti hai.</p>
                        </li>

                        <li className='flex items-start mb-2'>
                            <p className='text-[#27312C] font-[400] text-[10px] pr-2' style={{ lineHeight: '12.1px' }}>3. </p>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}> fake payment screenshot bhejne se apka betpro account Ban hojae ga.</p>
                        </li>
                        <li className='flex items-start mb-1'>
                            <p className='text-[#27312C] font-[400] text-[10px] pr-2' style={{ lineHeight: '12.1px' }}>4. </p>
                            <p className='text-[#27312C] font-[400] text-[10px]' style={{ lineHeight: '12.1px' }}> Hamesha Wallet me diye gaye number per hi amount send krain, dosre number per send karne se amount zaya hojae gi.</p>
                        </li>
                    </ul>
                </div>
                <div className="absolute bottom-[10%] right-5">
                    <button type="button">
                        <img src={Icons.logos_whatsapp} alt="logos_whatsapp" className='w-[45.37px] h-[45.37px]' />
                    </button>
                </div>
            </div>
        </>
    );
}

export default Main;
