import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import BottomTab from '../../components/BottomTab';

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Save the current path to localStorage whenever it changes
    if (location.pathname.startsWith('/dashboard')) {
      localStorage.setItem('lastDashboardPage', location.pathname);
    }
  }, [location]);

  useEffect(() => {
    // On mount, check localStorage for the last visited route and navigate to it
    const lastVisitedPage = localStorage.getItem('lastDashboardPage');
    if (lastVisitedPage && lastVisitedPage !== '/dashboard') {
      navigate(lastVisitedPage, { replace: true });
    }
  }, [navigate]);

  return (
    <div>
      <div>
        <Outlet />
      </div>
      <div className='h-[60px] w-full'></div>
      <BottomTab />
    </div>
  );
};

export default Dashboard;
